
  .myTable .ant-table-thead > tr > th {
    color: #fafafa;
    background: #4285f4;
	font-weight: bold;
	border: 1px solid white;
  }

  .myCard .ant-card-head {
	color: #fafafa;
    background: #4285f4;
	font-weight: bold;
  }

  .myCardWithoutPadding .ant-card-body {
	padding: 0px;
  }


  .last-row {
	background-color: #4285f4;
	color: white;
  }
  .last-row:hover {
	background-color: white;
	color: #4285f4;
  }

 .myCardPadding5 .ant-card-body {
    padding: 5px;
}

