@import '~antd/dist/antd.less';

@font-face {
    font-family: 'Trueno Light';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Light'), url('fonts/TruenoLt.woff') format('woff');
}

@font-face {
    font-family: 'Trueno Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trueno Regular'), url('fonts/TruenoRg.woff') format('woff');
}

@primary-color: #1dabdd;
@info-color: @primary-color;
@success-color: rgb(49, 187, 112);
@warning-color: rgb(255, 187, 78);
@error-color: #ff362b;
@highlight-color: lighten(#ff362b, 50%);
@normal-color: #a3a6a7;
@body-background: #f5f5f5;

@text-color: #354052;
@font-size-base: 18px;
@font-size-sm: 16px;

@height-base: 40px;

@layout-header-color: #354052;
@layout-body-background: @body-background;
@layout-header-background: #fff;
@layout-sider-background: #354052;
@layout-trigger-background: @layout-sider-background;
@layout-trigger-color: @menu-dark-color;

@menu-dark-color: #f2f2f2;
@menu-dark-bg: @layout-sider-background;

@btn-primary-bg: @primary-color;
@btn-default-bg: @layout-header-color;
@btn-default-color: @menu-dark-color;
@btn-font-size-sm: @font-size-sm;
@btn-height-sm: @height-base - 4px;

@btn-default-ghost-color: @text-color;
@btn-default-ghost-border: @text-color;

@modal-heading-color: @text-color;

@table-header-color: #397086;
@label-required-color: @error-color;

@font-family: "Trueno Light", sans-serif;

.ant-table-title {
	color: @table-header-color;
	background-color: @table-header-bg;
}

.ant-layout-sider-children .ant-typography {
	color: @menu-dark-color;
}

// .ant-table {

// 	overflow: scroll;

// }
